



























































































































































































































































import _ from 'lodash';
import mime from 'mime-types';
import { mapGetters } from 'vuex';
import { Form } from '../components/admin';
import { AdminFormMixin, NotificationMixin } from '../mixins';
import { required } from 'vuelidate/lib/validators';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import axios from 'axios';

@Component({
  name: 'Settings',
  components: {
    Form,
  },
  created() {
    const params = {
      perPage: 1000,
    };
    this.$store.dispatch('survey/list', { key: 'dropdown', params });
    this.$store.dispatch('client/list', { key: 'dropdown', params });
  },
  computed: {
    ...mapGetters('survey', ['getList']),
  },
})
export default class Settings extends Mixins(
  AdminFormMixin,
  NotificationMixin
) {
  prevFormData: any = {};
  formData: any = {};

  readonly $refs!: {
    importModal: BModal;
    deleteReportsModal: BModal;
    deleteSurveysModal: BModal;
    deleteClientsModal: BModal;
    deleteResponsesModal: BModal;
  };

  readonly $v!: any;

  // GETTERS
  get accept() {
    return [mime.lookup('xlsx'), mime.lookup('xls')].join(',');
  }

  get submitButtonText() {
    return this.isImportMode ? 'Import' : 'Create Sample Responses';
  }

  get modalTitle() {
    return this.isImportMode ? 'Import Responses' : 'Add Responses';
  }

  get isFormValid() {
    return this.isImportMode
      ? this.formData.surveyId && this.formData.file
      : this.formData.surveyId && this.formData.totalResponses;
  }

  get isImportMode() {
    return !this.formData.totalResponses;
  }

  get clients() {
    return this.$store.getters['client/getList']('dropdown');
  }

  get projectCodes() {
    if (this.formData.client) {
      const projectCodes = _.filter(
        this.$store.getters['survey/getList']('dropdown'),
        (survey) => {
          return survey.client.id === this.formData.client;
        }
      ).map((survey) => {
        return {
          id: survey.projectCode,
          name: survey.projectCode.toUpperCase(),
        };
      });
      return _.sortBy(_.uniqBy(projectCodes, 'id'), ['id']);
    }
    return [];
  }

  get teamNames() {
    if (this.formData.client && this.formData.projectCode) {
      const teamNames = _.filter(
        this.$store.getters['survey/getList']('dropdown'),
        (survey) => {
          return (
            survey.client.id === this.formData.client &&
            survey.projectCode === this.formData.projectCode
          );
        }
      ).map((survey) => {
        return {
          id: survey.teamName,
          name: survey.teamName,
        };
      });
      return _.sortBy(_.uniqBy(teamNames, 'id'), ['id']);
    }
    return [];
  }

  get surveys() {
    if (
      this.formData.client &&
      this.formData.projectCode &&
      this.formData.teamName
    ) {
      if (this.isImportMode) {
        return _.filter(
          this.$store.getters['survey/getList']('dropdown'),
          (survey) => {
            return (
              survey.client.id === this.formData.client &&
              survey.projectCode === this.formData.projectCode &&
              survey.teamName === this.formData.teamName
            );
          }
        );
      } else {
        return _.filter(
          this.$store.getters['survey/getList']('dropdown'),
          (survey) => {
            return (
              survey.client.id === this.formData.client &&
              survey.projectCode === this.formData.projectCode &&
              survey.teamName === this.formData.teamName
            );
          }
        );
      }
    }
    return [];
  }

  // METHODS
  importResponses() {
    this.formData = {};
    this.$refs.importModal.show();
  }

  addResponses() {
    this.formData = { totalResponses: 10 };
    this.$refs.importModal.show();
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.notifyError('Please make sure all required fields are filled.');
      return;
    }

    const surveyId = this.formData.surveyId;
    if (this.isImportMode) {
      const formData = new FormData();
      formData.append('file', this.formData.file);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      this.notifyInfo('Importing responses...');
      axios
        .post(`/surveys/${surveyId}/responses`, formData, options)
        .then(() => {
          this.notifySuccess('Responses imported.');
          this.$refs.importModal.hide();
          this.onReset();
        })
        .catch((err) => {
          const errMessage = err.message || 'Failed to import responses';
          this.notifyError(errMessage);
        });
    } else {
      this.notifyInfo('Creating sample responses...');
      const data = {
        totalResponses: this.formData.totalResponses,
      };
      axios
        .post(`/surveys/${surveyId}/responses`, data)
        .then(() => {
          this.notifySuccess('Responses created.');
          this.$refs.importModal.hide();
          this.onReset();
        })
        .catch((err) => {
          const errMessage = err.message || 'Failed to create responses';
          this.notifyError(errMessage);
        });
    }
  }

  onCancel() {
    this.onReset();
    this.$refs.importModal.hide();
  }

  onReset() {
    this.$set(this.formData, 'client', null);
    this.$set(this.formData, 'projectCode', null);
    this.$set(this.formData, 'teamName', null);
    this.$set(this.formData, 'surveyId', null);
    this.$set(this.formData, 'file', null);
  }

  onChange(evt) {
    if (this.formData.client !== this.prevFormData.client) {
      this.$set(this.formData, 'projectCode', null);
      this.$set(this.formData, 'teamName', null);
      this.$set(this.formData, 'surveyId', null);
    } else if (this.formData.projectCode !== this.prevFormData.projectCode) {
      this.$set(this.formData, 'teamName', null);
      this.$set(this.formData, 'surveyId', null);
    } else if (this.formData.teamName !== this.prevFormData.teamName) {
      this.$set(this.formData, 'surveyId', null);
    }
    this.prevFormData = JSON.parse(JSON.stringify(this.formData));
  }

  handleDeleteResponses() {
    return this.$store
      .dispatch('survey/deleteResponses')
      .then((res) => {
        const msg = res.message || 'Successfully deleted responses.';
        this.notifySuccess(msg);
        this.$refs.deleteResponsesModal.hide();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to delete responses';
        this.notifyError(errMessage);
      });
  }

  handleDeleteSurveys() {
    return this.$store
      .dispatch('survey/deleteMany')
      .then((res) => {
        const msg = res.message || 'Successfully deleted surveys.';
        this.notifySuccess(msg);
        this.$refs.deleteSurveysModal.hide();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to delete surveys';
        this.notifyError(errMessage);
      });
  }

  handleDeleteReports() {
    return this.$store
      .dispatch('report/deleteMany')
      .then((res) => {
        const msg = res.message || 'Successfully deleted reports.';
        this.notifySuccess(msg);
        this.$refs.deleteReportsModal.hide();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to delete reports';
        this.notifyError(errMessage);
      });
  }

  handleDeleteClients() {
    return this.$store
      .dispatch('client/deleteMany')
      .then((res) => {
        const msg = res.message || 'Successfully deleted clients.';
        this.notifySuccess(msg);
        this.$refs.deleteClientsModal.hide();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to delete clients';
        this.notifyError(errMessage);
      });
  }

  // VALIDATIONS
  validations() {
    return {
      formData: {
        client: {
          required,
        },
        projectCode: {
          required,
        },
        teamName: {
          required,
        },
        surveyId: {
          required,
        },
      },
    };
  }
}
